import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  SG_CATEGORY_SUMMARY_DEBRIEF_RESPONSE,
  SG_GET_SURVEY_STRUCTURES,
} from "constants/actions";

import {
  Button,
  Modal,
  Header,
  Dimmer,
  Loader,
  Segment,
  Grid,
  Table,
  Icon,
} from "semantic-ui-react";

export default function CategorySummary({ debriefDetails }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [catOptions, setCatOptions] = useState([]);
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (debriefDetails?.organization) {
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${debriefDetails?.organization}`,
      });
    }
  }, [dispatch, debriefDetails]);

  const { get_survey_structure, get_category_summary } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure.survey_structure,
      get_category_summary: state.debrief_schedule,
    }),
    shallowEqual
  );

  useEffect(() => {
    setLoading(get_category_summary?.pending);
  }, [get_category_summary]);

  const handleCategoryChange = (selectedOption) => {
    const catOptionList = get_survey_structure?.results?.[0]?.categories.find(
      ({ id }) => id === selectedOption.value
    )?.options;
    dispatch({
      type: SG_CATEGORY_SUMMARY_DEBRIEF_RESPONSE,
      payload: {
        category_id: selectedOption.value,
        category_options: catOptionList,
        debrief_schedule_id: debriefDetails?.id,
      },
    });
  };

  const categoryOptions = get_survey_structure?.results?.[0]?.categories.map(
    (category) => ({
      value: category.id,
      label: category.name,
    })
  );

  useEffect(() => {
    setCatOptions(get_category_summary?.category_summary?.summary?.summary);
  }, [get_category_summary]);

  const handleIconClick = (option) => {
    setSelectedOption(option);
    setSecondModalOpen(true);
  };

  return (
    <>
      <Modal
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={
          <Button floated="right" color="orange">
            Category Summary
          </Button>
        }
      >
        <Modal.Description>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Dimmer.Dimmable as={Segment} dimmed={loading}>
                  <Dimmer active={loading}>
                    <Loader />
                    <Header as="h2" icon inverted>
                      Loading, Please wait.
                    </Header>
                  </Dimmer>
                  <OuterContainer>
                    <HeaderDiv>Select to View Summary</HeaderDiv>

                    <Select
                      options={categoryOptions}
                      onChange={handleCategoryChange}
                      placeholder="Select a category"
                    />

                    {catOptions && (
                      <Table celled>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>CategoryId</Table.HeaderCell>
                            <Table.HeaderCell>Category</Table.HeaderCell>
                            <Table.HeaderCell>Request</Table.HeaderCell>
                            <Table.HeaderCell>Completion</Table.HeaderCell>
                            <Table.HeaderCell>Percent</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {catOptions &&
                            catOptions.map((option) => (
                              <Table.Row key={option?.categorty_id}>
                                <Table.Cell>{option?.categorty_id}</Table.Cell>
                                <Table.Cell>{option?.category_name}</Table.Cell>
                                <Table.Cell>
                                  {option?.requests}
                                  <Icon
                                    name="info circle"
                                    onClick={() => handleIconClick(option)}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  {option?.compliances}
                                  <Icon
                                    name="info circle"
                                    onClick={() => handleIconClick(option)}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  {option?.requests
                                    ? Math.round(
                                        (option?.compliances /
                                          option?.requests) *
                                          100
                                      )
                                    : 0}{" "}
                                  %
                                </Table.Cell>
                              </Table.Row>
                            ))}
                        </Table.Body>
                      </Table>
                    )}
                  </OuterContainer>
                </Dimmer.Dimmable>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
      </Modal>

      <Modal
        closeIcon
        onClose={() => setSecondModalOpen(false)}
        open={secondModalOpen}
      >
        <Modal.Header>Details</Modal.Header>
        <Modal.Content>
          <DetailsSection>
            <strong>Request List:</strong>
            <DetailsBox>
              {selectedOption?.request_list?.map((request) => (
                <DetailsItem key={request}>
                  <Link
                    to={`/app/employees/details/${request}`}
                    preventscrollreset="true"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {request}
                  </Link>
                </DetailsItem>
              ))}
            </DetailsBox>
          </DetailsSection>
          <DetailsSection>
            <strong>Compliance List:</strong>
            <DetailsBox>
              {selectedOption?.compliance_list?.map((compliance) => (
                <DetailsItem key={compliance}>
                  <Link
                    to={`/app/employees/details/${compliance}`}
                    preventscrollreset="true"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {compliance}
                  </Link>
                </DetailsItem>
              ))}
            </DetailsBox>
          </DetailsSection>
        </Modal.Content>
      </Modal>
    </>
  );
}

const OuterContainer = styled.div`
  margin: 20px 10px;
  min-height: 400px; /* Set a minimum height for the modal */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const HeaderDiv = styled.div`
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
`;

const ColorBox = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
`;

const DetailsSection = styled.div`
  margin-bottom: 20px;
  background-color: #fff;
`;

const DetailsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;

  border-radius: 4px;
`;

const DetailsItem = styled.div`
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 4px;
`;
